import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`30th March 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">{`You can now make your client connect via a SOCKS 4, SOCKS 5 or HTTP CONNECT proxy server. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2321"
        }}>{`#2321`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2496"
        }}>{`#2496`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory.builder()
             .proxyConfig(ProxyConfig.socks4(socksProxyServer.address()))
             .build();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now configure to send `}<inlineCode parentName="li">{`PING`}</inlineCode>{` on idle HTTP/2 connections. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1263"
        }}>{`#1263`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2409"
        }}>{`#2409`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ClientFactory.builder()
             .http2PingTimeoutMillis(3000)
             .build();
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now make an alias for the pre-defined MDC key when using `}<inlineCode parentName="li">{`RequestContextExportingAppender`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2512"
        }}>{`#2512`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2531"
        }}>{`#2531`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<export>request_id=req.id</export> <!-- request_id is an alias for the built-in property. -->
<export>my_foo_bar=attr:com.example.Foo#ATTR_BAR</export>    <!-- my_foo_bar is an alias. -->
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`Please check out `}<a parentName="li" {...{
              "href": "http://armeria.dev/docs/advanced-logging#customizing-mdc-keys"
            }}>{`http://armeria.dev/docs/advanced-logging#customizing-mdc-keys`}</a>{` for more information.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`You can now set an example path and queries for `}<inlineCode parentName="li">{`DocService`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2195"
        }}>{`#2195`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2546"
        }}>{`#2546`}</a></li>
      <li parentName="ul">{`You can now customize the default log name of a service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2626"
        }}>{`#2626`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`sb.route().addRoute(...).defaultLogName("name").build(service);
`}</code></pre>
      </li>
      <li parentName="ul">{`You can now access a parent log from a child log using `}<inlineCode parentName="li">{`RequestLogAccess.parent()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2599"
        }}>{`#2599`}</a></li>
      <li parentName="ul">{`gRPC client now supports gRPC `}<inlineCode parentName="li">{`CallCredentials`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2619"
        }}>{`#2619`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2622"
        }}>{`#2622`}</a></li>
      <li parentName="ul">{`You can now send customized responses from `}<inlineCode parentName="li">{`ThrottlingService`}</inlineCode>{` using `}<inlineCode parentName="li">{`ThrottlingAcceptHandler`}</inlineCode>{` and `}<inlineCode parentName="li">{`ThrottlingRejectHandler`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2482"
        }}>{`#2482`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2503"
        }}>{`#2503`}</a></li>
      <li parentName="ul">{`You can now use advanced throttling algorithm based on Token-Bucket rate-limiting and Bucket4j library. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2566"
        }}>{`#2566`}</a></li>
      <li parentName="ul">{`You can now create your own storage to store `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2514"
        }}>{`#2514`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2610"
        }}>{`#2610`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// You should implement \`RequestContextStorageProvider\` and use SPI to return the \`RequestContextStorage\`.
public class CustomizedStorageProvider implements RequestContextStorageProvider {
    @Override
    public RequestContextStorage newStorage() {
        return new CustomizedStorage();
    }
}
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now set timeout using `}<inlineCode parentName="p">{`TimeoutMode.`}</inlineCode>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2535"
          }}>{`#2535`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2571"
          }}>{`#2571`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Before
ctx.extendResponseTimeoutMillis(10000);
ctx.setResponseTimeoutAfterMillis(10000);

// After
ctx.setResponseTimeoutMillis(TimeoutMode.EXTEND, 10000);
ctx.setResponseTimeoutMillis(TimeoutMode.SET_FROM_NOW, 10000);
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`set(Request|Response)Timeout`}</inlineCode>{` is now undeprecated.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`DNS resolution is timed out after `}<inlineCode parentName="li">{`queryTimeoutMillis`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2623"
        }}>{`#2623`}</a></li>
      <li parentName="ul">{`You can now return `}<inlineCode parentName="li">{`Single<HttpResponse>`}</inlineCode>{` or `}<inlineCode parentName="li">{`Maybe<HttpResponse>`}</inlineCode>{` in an annotated service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2624"
        }}>{`#2624`}</a></li>
      <li parentName="ul">{`You can now register and monitor Reactor `}<inlineCode parentName="li">{`Scheduler`}</inlineCode>{` with Prometheus in Armeria Server. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2612"
        }}>{`#2612`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestLog.name`}</inlineCode>{` and some other properties are copied to the child log in `}<inlineCode parentName="li">{`RetyringClient`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2551"
        }}>{`#2551`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2560"
        }}>{`#2560`}</a></li>
      <li parentName="ul">{`You no longer see `}<inlineCode parentName="li">{`ClosedStreamException`}</inlineCode>{` due to the stream creation reversal. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2597"
        }}>{`#2597`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`IllegalStateException`}</inlineCode>{` is not raised anymore while building a WebFlux response. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2613"
        }}>{`#2613`}</a></li>
      <li parentName="ul">{`Annotated service's log name is always set. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2626"
        }}>{`#2626`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul">{`Deprecated `}<inlineCode parentName="li">{`ZooKeeperUpdatingListener.nodeValueCodec()`}</inlineCode>{` in favor of `}<inlineCode parentName="li">{`ZooKeeperUpdatingListener.codec()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2552"
        }}>{`#2552`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Most of the deprecated APIs are gone. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2543"
          }}>{`#2543`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`If you have trouble finding APIs to replace, please upgrade to 0.98.7 first and use your IDE's advanced feature to search deprecated APIs. Then, you can easily upgrade to 0.99.0.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`armeria-dropwizard`}</inlineCode>{` is now for Dropwizard 2. If you want to use Dropwizard `}<inlineCode parentName="p">{`1.3.x`}</inlineCode>{`, use `}<inlineCode parentName="p">{`armeria-dropwizard1`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2556"
          }}>{`#2556`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`armeria-rxjava`}</inlineCode>{` is now for RxJava 3. If you want to use RxJava 2, use `}<inlineCode parentName="p">{`armeria-rxjava2`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2378"
          }}>{`#2378`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2501"
          }}>{`#2501`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ClientBuilder.path(path)`}</inlineCode>{` is now gone. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2525"
          }}>{`#2525`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2538"
          }}>{`#2538`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`If you want to specify the path other than `}<inlineCode parentName="li">{`/`}</inlineCode>{`, you should specify it when creating `}<inlineCode parentName="li">{`ClientBuilder`}</inlineCode>{`.`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// before
Clients.builer(SessionProtocol.HTTP, Endpoint.of("127.0.0.1"))
       .path("/foo");
// after
Clients.builer(SessionProtocol.HTTP, Endpoint.of("127.0.0.1"), "/foo");
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ClosedSessionException`}</inlineCode>{` now extends `}<inlineCode parentName="p">{`ClosedStreamException`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2596"
          }}>{`#2596`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2616"
          }}>{`#2616`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`AuthSuccessHandler<HttpRequest, HttpResponse>`}</inlineCode>{` and `}<inlineCode parentName="p">{`AuthFailureHandler<HttpRequest, HttpResponse>`}</inlineCode>{` now don't have type parameters.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The meter name `}<inlineCode parentName="p">{`armeriaBlockingTaskExecutor`}</inlineCode>{` is now `}<inlineCode parentName="p">{`blockingTaskExecutor`}</inlineCode>{` with the prefix `}<inlineCode parentName="p">{`armeria.executor`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`RequestContext.set*TimeoutAfter*()`}</inlineCode>{` or `}<inlineCode parentName="p">{`extend*Timeout*()`}</inlineCode>{` methods have been removed. Call `}<inlineCode parentName="p">{`set*Timeout*()`}</inlineCode>{` with a `}<inlineCode parentName="p">{`TimeoutMode`}</inlineCode>{` specified instead.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.9.5 -> 5.10.2`}</li>
      <li parentName="ul">{`bucket4j-core 4.10.0`}</li>
      <li parentName="ul">{`Curator 4.2.0 -> 4.3.0`}</li>
      <li parentName="ul">{`java-jwt 3.10.0 -> 3.10.1`}</li>
      <li parentName="ul">{`jetty-alpn-agent 2.0.9 -> 2.10.0`}</li>
      <li parentName="ul">{`Micrometer 1.3.5 -> 1.4.0`}</li>
      <li parentName="ul">{`Netty 4.1.37.Final -> 4.1.48.Final`}
        <ul parentName="li">
          <li parentName="ul">{`netty-tcnative-boringssl-static 2.0.29.Final -> 2.0.30.Final`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Opensaml 3.4.3 -> 3.4.5`}</li>
      <li parentName="ul">{`Reactor 3.3.3.RELEASE -> 3.3.4.RELEASE`}</li>
      <li parentName="ul">{`Retrofit 2.7.2 -> 2.8.0`}</li>
      <li parentName="ul">{`RxJava 3.0.0 -> 3.0.1`}</li>
      <li parentName="ul">{`Spring Boot 2.2.4 -> 2.2.5`}
        <ul parentName="li">
          <li parentName="ul">{`Spring2.1 2.1.12.RELEASE -> 2.1.13.RELEASE`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Tomcat 9.0.31 -> 9.0.33`}
        <ul parentName="li">
          <li parentName="ul">{`Tomcat8.5 8.5.51 -> 8.5.53`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Zookeeper 3.5.7 -> 3.6.0`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['alex-lx', 'AmosDoan', 'andrewom', 'anuraaga', 'codefromthecrypt', 'cricket007', 'heowc', 'hexoul', 'ikhoon', 'imasahiro', 'jyblue', 'joschi', 'jrhee17', 'KarboniteKream', 'kojilin', 'mauhiz', 'max904-github', 'minwoox', 'rstoyanchev', 'sivaalli', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      